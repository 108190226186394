/**
 *
 * @param {{ size: 'small | medium | large'}} param0
 * @returns
 */
export default function Spacer({ size, height }) {
  const finalHeight = getSizeInPx(size) || '1rem';
  return <div style={{ height: finalHeight }} />;
}

function getSizeInPx(size) {
  switch (size) {
    case 'small':
      return '4px';
    case 'medium':
      return '8px';
    case 'large':
      return '106px';
  }
}
